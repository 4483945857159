<template>
  <div>
    <vs-button class="w-full md:w-auto h-12 text-center py-2 my-1 md:my-0 text-sm" :disabled="disabled" @click="exportPopup=true">
      Export
    </vs-button>

    <vs-popup class="popUpCustomPdf"  title="Export Drugbook" :active.sync="exportPopup" >
      <div class="p-6 overflow-visible" >
        <!-- clinics -->
        <div class="mb-2">
          <label for="selectedClinic">Clinics</label>
          <multiselect
            id="selectedClinic"
            class="custom-multiselect"
            v-model="selectedClinic"
            :options="clinicsList"
            label="name"
            :loading="!!clinicsList.length===0"
            placeholder="Select Clinic"
            :clear-on-select="false"
            track-by="clinicId"
            :max-height="200"
            />
          <vs-icon
            v-if="selectedClinic > 0"
            icon="close"
            color="red"
            bg="white"
            class="removeSelectedFilters"
            @click="selectedClinic = null">
          </vs-icon>
        </div>
        <!-- products -->
        <div class="mb-2">
          <label for="selectedProducs">Products</label>
          <multiselect
            id="selectedProducs"
            class="custom-multiselect"
            v-model="selectedProducts"
            :options="extendedDrugItems"
            :multiple="true"
            label="name"
            :loading="!!drugItems.length===0"
            placeholder="Select Product"
            :clear-on-select="false"
            track-by="_id"
            :max-height="200"
            />
          <vs-icon
            v-if="drugItems.length > 0"
            icon="close"
            color="red"
            bg="white"
            class="removeSelectedFilters"
            @click="selectedProducts = []">
          </vs-icon>
        </div>
        <!-- start date -->
        <div class="mb-2">
          <label for="selectedStartDate">Start Date</label>
          <Datepicker
            format="dd MMMM yyyy"
            v-model="startDate"
            :typeable="true"
            placeholder="Start Date"
          />
        </div>
        <!-- end date -->
        <div class="mb-2">
          <label for="selectedEndDate">End Date</label>
          <Datepicker
            format="dd MMMM yyyy"
            v-model="endDate"
            :typeable="true"
            placeholder="End Date"
          />
        </div>
        <div class="flex flex-wrap md:flex-no-wrap items-center justify-between space-y-2 md:space-y-0 md:space-x-4 mt-5">
          <div class="w-full md:flex-1 downloadPdf">
            <vs-button type="border" class="w-full" @click="exportPopup=false">
              Cancel
            </vs-button>
          </div>
          <div class="w-full md:flex-1">
            <vs-button class="w-full" :disabled="disableExport" @click="genratePdfReport">Download PDF</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import { Field } from "vee-validate";


export default {
  props:[
    "clinicsList",
    "drugItems",
    "organizationId",
    "disabled"
  ],
  components: {
    Multiselect,
    Datepicker
  },
  data: () => ({
    exportPopup:false,
    selectedClinic: null,
    selectedProducts: [],
    startDate: null,
    endDate: null,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
  }),
  computed: {
    extendedDrugItems() {
      return [
        { name: "All products", _id: "all_products" },
        ...this.drugItems
      ];
    },
    disableExport(){
      return !(
        this.startDate
        && this.startDate !== ""
        && this.endDate
        && this.endDate !== ""
        && this.selectedClinic
        && this.selectedClinic !== ""
        && this.selectedProducts.length
      )
    }
  },
  methods:{
    ...mapActions("drugbook", [
      "exportDrugEntriesCustomPDF",
    ]),
    ...mapActions("monthlyCompliance", [
      "downloadPdf",
    ]),
    preselectFields(){
      const clinic = this.$route.query.selectedClinic || ''
      const product = this.$route.query.selectedDrug || ''
      this.selectedClinic = this.clinicsList.filter(obj => obj.clinicId === clinic)[0]
      this.selectedProducts = this.drugItems.filter(obj => obj._id === product)
    },
    async genratePdfReport(){
      if (this.disableExport) {
        this.$vs.notify({
            title: "Export drugbook failed!",
            text: "Fields are required",
            color: "danger",
          });
        return
      }
      try {
        this.$vs.loading();
        const clinicId = this.selectedClinic.clinicId;
        const payload = {
          clinicId,
          startDate: moment(this.startDate).format('YYYY-MM-DD'),
          endDate: moment(this.endDate).format('YYYY-MM-DD'),
          organizationId: this.organizationId,
          timeZone: this.timeZone
        }
        if (!this.selectedProducts.some(item => item._id === "all_products")){
          const productIds = this.selectedProducts.map(product => product._id);
          payload.productIds = productIds
        }
        const res = await this.exportDrugEntriesCustomPDF(payload);
        const clinic = _.find(this.clinicsList,['clinicId',clinicId]).name
        await this.DownloadPdf({
          url: res.data.data.Location,
          name: `${moment(this.startDate).format("MMMM DD YYYY")}-${moment(this.endDate).format("MMMM DD YYYY")}-${clinic}`
        })
        this.$vs.loading.close()
        this.exportPopup = false;
      } catch (error) {
        this.$vs.notify({
            title: "Export drugbook failed!",
            text: "Fields are required",
            color: "danger",
          });
        this.$vs.loading.close()
        console.error("ERR: ", error);
      }
    },
    async DownloadPdf({ url, name }) {
      const res = await this.downloadPdf(url);
      const blob = new Blob([res.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${name}-drugbook.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
  watch:{
    selectedProducts(newVal, oldVal){
      let filteredProducts;
      if (newVal.length && newVal[newVal.length - 1]._id === "all_products") {
        filteredProducts = [{ name: "All products", _id: "all_products" }];
      } else {
        filteredProducts = newVal.filter(item => item._id !== "all_products");
      }

      // Avoid infinite loop by checking if the filtered array is different from the original
      if (JSON.stringify(filteredProducts) !== JSON.stringify(this.selectedProducts)) {
        this.selectedProducts = filteredProducts;
      }
    },
    exportPopup (newVal, oldVal){
      if(!newVal){
        this.selectedClinic = null;
        this.selectedProducts = [];
        this.startDate = '';
        this.endDate = ''
      }
      else{
        this.preselectFields()
      }
    }
  },
}
</script>
<style>
  .popUpCustomPdf .vs-popup--content {
    overflow: visible !important;
  }
  .popUpCustomPdf  {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 14px !important;
  }
  .popUpCustomPdf .multiselect__tags .multiselect__single {
    font-size: 14px !important;
  }
  .popUpCustomPdf .multiselect__input  {
    font-size: 14px !important;
  }
  .popUpCustomPdf .multiselect__content  {
    font-size: 14px !important;
  }
  .popUpCustomPdf .vdp-datepicker ::placeholder{
    color: #adadad !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    opacity: 50;
  }
  .popUpCustomPdf .vdp-datepicker {
    font-size: 14px !important;
  }
  .popUpCustomPdf .vdp-datepicker input{
    border: 1px solid #e8e8e8;
  }
  .customSelect {

    .vs-select--label,
    .vs-select--label.input-select-label-primary--active {
      color: rgba(0, 0, 0, 0.6);
    }
    .vs-select--input {
      border: 0;
      font-family: inherit;
      font-size: 16px;
      margin-top: 0.4rem;
      padding: 0;
    }
    .vs-select--input:disabled {
      opacity: 100;
      pointer-events: none;
      background-color: transparent;
    }
  }
  .downloadPdf .vs-button{
    padding: 8px 12px 8px 12px !important;
  }
  .vs-button-primary{
    border: 1px solid rgba(var(--vs-primary), 1);
  }
</style>
